var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-dir": "column"
    }
  }, [_c('HeaderComp'), _c('c-box', {
    attrs: {
      "max-w": ['inherit', '1700px'],
      "w": "100%",
      "mx": "auto",
      "overflow": "auto"
    }
  }, [_c('c-flex', {
    attrs: {
      "max-w": "1700px",
      "padding-right": "6px"
    }
  }, [_c('Sidemenu'), _c('c-flex', {
    attrs: {
      "pt": "42px",
      "pl": "30px",
      "mb": "130px",
      "w": "923px",
      "flex-grow": "1"
    }
  }, [_c('router-view')], 1)], 1)], 1), _c('FooterComp')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }